import { v4 as uuid } from 'uuid';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component

import SortIcons from 'shared/components/flux-virtualized-table/SortIcons';

import './FluxVirtualizedTable.scss';

const FluxVirtualizedTable = ({
  data,
  columns,
  rowHeight = 56,
  bufferRows = 10,
  visibleRows = 9,
  filterString = '',
  savedFilters = [],
  tableWidth = '100%',
  tableHeight = '100%',
  handleRowClick = undefined,
  generateLinkForTableRow = undefined,
  sortConfig = null,
  setSortConfig,
}) => {
  const tableBodyRef = useRef(null);

  const [visibleData, setVisibleData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [currentScrollTop, setCurrentScrollTop] = useState(0);

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;
    setCurrentScrollTop(scrollTop);
  };

  const applyFilters = (currentData, filters) => filters.reduce((newFilteredData, filter) => {
    const formattedFilter = filter?.toLowerCase();
    if (formattedFilter?.length) {
      return newFilteredData?.filter((entry) => Object.keys(entry).some((key) => {
        const value = entry[key];
        if (typeof value === 'string' || typeof value === 'number') {
          return value?.toString()?.toLowerCase()?.includes(formattedFilter);
        }
        return false;
      }));
    }
    return newFilteredData;
  }, currentData);

  const updateVisibleData = (newScrollTop) => {
    const filteredData = applyFilters(sortedData, [...savedFilters, filterString]);
    const startIndex = Math.max(Math.floor(newScrollTop / rowHeight) - bufferRows, 0);
    const endIndex = Math.min(startIndex + visibleRows + 2 * bufferRows, filteredData.length);
    setVisibleData(filteredData.slice(startIndex, endIndex));
  };

  const sortByColumn = (dataIndex, initialDirection = 'ascending', rerenderSort = false) => {
    let direction = initialDirection;
    if (sortConfig.key === dataIndex && sortedData.length && !rerenderSort) {
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }

    const sortedItems = [...data].sort((a, b) => {
      let valueA = a[dataIndex];
      let valueB = b[dataIndex];

      if (dataIndex === 'last_synced') {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      }

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return direction === 'ascending' ? valueA - valueB : valueB - valueA;
      }

      if (valueA instanceof Date && valueB instanceof Date) {
        return direction === 'ascending' ? valueA - valueB : valueB - valueA;
      }

      valueA = String(valueA).toLowerCase();
      valueB = String(valueB).toLowerCase();

      if (valueA < valueB) return direction === 'ascending' ? -1 : 1;
      if (valueA > valueB) return direction === 'ascending' ? 1 : -1;

      return 0;
    });

    setSortedData(sortedItems);
    if (!rerenderSort) setSortConfig({ key: dataIndex, direction });
  };

  useEffect(() => {
    if (!sortedData.length && data.length) {
      const defaultSortColumn = columns.find((column) => column.defaultSort);
      if (defaultSortColumn) {
        const { dataIndex, defaultSort } = defaultSortColumn;
        sortByColumn(dataIndex, defaultSort);
      } else if (data.length) {
        const { dataIndex } = columns.at(0);
        sortByColumn(dataIndex, 'ascending');
      }
      setInitialData(data);
    }
  }, [data]);

  useEffect(() => {
    if (data.length && !initialData?.length) setInitialData(data);
    if (data.length && initialData && data !== initialData) {
      sortByColumn(sortConfig.key, sortConfig.direction, true);
    }
  }, [data]);

  useEffect(() => {
    updateVisibleData(currentScrollTop);
  }, [currentScrollTop, filterString, savedFilters.length, sortedData]);

  return (
    <div
      className="flux-virtualized-table"
      style={{ width: tableWidth, height: tableHeight }}
    >
      <div className="table-header">
        <div className="table-header-row">
          {columns.map(({ title, dataIndex, sortable }) => (
            <div
              key={dataIndex}
              onClick={() => sortable && sortByColumn(dataIndex)}
              className={`table-header-row-cell ${sortable && 'hover'}`}
            >
              <span className="table-header-row-cell-value">{title}</span>
              {sortable && <SortIcons sortConfig={sortConfig} dataIndex={dataIndex} />}
            </div>
          ))}
        </div>
      </div>
      <div
        ref={tableBodyRef}
        className="table-body"
        onScroll={handleScroll}
        style={{
          overflowY: 'auto',
          maxHeight: `${visibleRows * rowHeight}px`,
        }}
      >
        <div
          style={{
            position: 'relative',
            height: `${sortedData.length * rowHeight}px`,
          }}
        >
          {visibleData?.map((item, index) => {
            const actualIndex = Math.max(Math.floor(currentScrollTop / rowHeight) - bufferRows, 0) + index;
            const rowContent = (
              <>
                {columns.map(({ dataIndex, render }) => (
                  <div key={dataIndex} className="table-body-row-cell">
                    {render
                      ? (
                        <span className="table-body-row-cell-rendered">
                          {render(item[dataIndex], item)}
                        </span>
                      )
                      : (
                        <span className="table-body-row-cell-value">
                          {item[dataIndex]}
                        </span>
                      )}
                  </div>
                ))}
              </>
            );

            return generateLinkForTableRow ? (
              <Link
                key={uuid()}
                className="table-body-row"
                to={generateLinkForTableRow(item)}
                style={{
                  width: '100%',
                  position: 'absolute',
                  height: `${rowHeight}px`,
                  top: `${(actualIndex) * rowHeight}px`,
                  color: '#1B1A1A',
                  textEmphasis: 'none',
                  textDecoration: 'none',
                  backgroundColor: (actualIndex % 2 !== 0) && '#fff',
                }}
              >
                {rowContent}
              </Link>
            ) : (
              <div
                key={uuid()}
                className="table-body-row"
                onClick={(e) => (handleRowClick ? handleRowClick(e, item) : () => {})}
                style={{
                  width: '100%',
                  position: 'absolute',
                  height: `${rowHeight}px`,
                  top: `${(actualIndex) * rowHeight}px`,
                  backgroundColor: (actualIndex % 2 !== 0) && '#fff',
                }}
              >
                {rowContent}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FluxVirtualizedTable;
