import { v4 as uuidv4 } from 'uuid';
import { URL_PREFIX } from 'shared/repositories/urls';
import defaultDriverLayouts from 'single-asset/constants/defaultDriverLayouts';

export interface LegacyAssetInfo {
  asset_id: number;
  device_id: number;
  org_id: number;
  site_id: number;
  asset_name: string;
  driver: 'GED' | 'ELEC';
}

export interface ChartLayout {
  order: number;
  title: string;
  chartValues: {
    key: string;
    tag: string;
    color: string;
    label: string;
  }[];
}

export interface Layout {
  id: string;
  version?: number;
  parentId?: string;
  unitId?: string;
  driver?: 'GED' | 'ELEC' | '*' | null;
  charts: ChartLayout[];
}

export interface ICharting {
  layouts?: Layout[];
}

export default class Charting {
  layouts?: Layout[];

  layoutFetchAttempts: number;

  emptyLayout: Layout = {
    id: '',
    parentId: '',
    unitId: '',
    driver: null,
    charts: [],
  };

  constructor({
    layouts,
  }: ICharting) {
    this.layouts = layouts;
    this.layoutFetchAttempts = 0;
  }

  setLayouts(layouts: Layout[]) {
    this.layouts = layouts;
  }

  // unitId is the unit name with ONLY numeric characters
  getUnitLayoutAndLayoutType(unitId: string, driver: 'GED' | 'ELEC') {
    if (!this.layouts) {
      return { unitLayout: defaultDriverLayouts[driver] ?? this.emptyLayout, layoutType: 'default' };
    }
    const unitLayout = this.layouts?.find((layout) => layout.unitId === unitId);

    if (unitLayout) {
      return { unitLayout, layoutType: 'unit' };
    }

    const driverLayout = this.layouts?.find((layout) => layout.driver === driver && (layout.unitId === '*' || layout.unitId === null));
    if (driverLayout) {
      return { unitLayout: driverLayout, layoutType: 'driver' };
    }

    const allUnitsLayout = this.layouts?.find((layout) => layout.unitId === '*' && (layout.driver === '*' || layout.driver === null));
    if (allUnitsLayout) {
      return { unitLayout: allUnitsLayout, layoutType: 'global' };
    }

    return { unitLayout: defaultDriverLayouts[driver] ?? this.emptyLayout, layoutType: 'default' };
  }

  // eslint-disable-next-line class-methods-use-this
  getApiChartBodiesFromLayout(layout: Layout, {
    asset_id,
    device_id,
    org_id,
    site_id,
  }: LegacyAssetInfo) {
    return layout.charts.map((chart) => ({
      series: chart.chartValues.map((chartValue) => ({
        asset_id,
        device_id,
        org_id,
        site_id,
        tag_id: chartValue.tag,
        label: chartValue.label,
        formattedLabel: chartValue.label.replace(/ /g, '-'),
        map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
        agg: {
          max: ['y'],
          min: ['y'],
          mean: ['y'],
        },
      })),
      url: `${chart.chartValues.map((cv) => cv.label.replace(/ /g, '-')).join(',')}`,
    })) ?? [];
  }

  getUnitLayoutAndApiChartBodies({
    asset_id,
    device_id,
    org_id,
    site_id,
    asset_name,
    driver,
  }: LegacyAssetInfo) {
    const unitId = asset_name.replace(/\D/g, '');
    const { unitLayout, layoutType } = this.getUnitLayoutAndLayoutType(unitId, driver);
    const transformedLayout = this.getApiChartBodiesFromLayout(unitLayout as Layout, {
      asset_id, device_id, org_id, site_id,
    } as LegacyAssetInfo);
    return { layout: unitLayout, chartBodies: transformedLayout, layoutType };
  }

  async fetchLayouts(userEmail: string, token: string) {
    this.layoutFetchAttempts += 1;
    try {
      const response = await fetch(`https://${URL_PREFIX}layouts.api.axil.ai/${userEmail}/layouts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const layouts = await response.json();
      this.layouts = layouts;
    } catch (error) {
      this.layouts = [];
    }
  }

  async updateLayout(existingLayout: Layout, updatedChartLayout: ChartLayout, token: string): Promise<Layout | null> {
    if (!existingLayout.id) return null;
    const layoutVersion = existingLayout.version ?? 0;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'if-match': layoutVersion.toString(),
      },
      method: 'PATCH',
      body: JSON.stringify(updatedChartLayout),
    };
    try {
      const response = await fetch(`https://${URL_PREFIX}layouts.api.axil.ai/layouts/${existingLayout.id}/charts`, requestOptions);
      const updatedLayout = await response.json();
      if (updatedLayout.statusCode && updatedLayout.statusCode !== 200) {
        throw new Error('Failed to update layout');
      }
      const newLayouts = this.layouts?.map((layout) => (layout.id === existingLayout.id ? updatedLayout : layout));
      this.layouts = newLayouts;
      return updatedLayout;
    } catch (err) {
      return null;
    }
  }

  async createLayout(newChartLayout: ChartLayout, userEmail: string, asset: LegacyAssetInfo, token: string): Promise<Layout | null> {
    if (!userEmail) return null;
    const layoutBody = {
      id: uuidv4(),
      parentId: userEmail,
      unitId: asset.asset_name.replace(/\D/g, ''),
      driver: asset.driver,
      charts: newChartLayout,
    };

    try {
      const response = await fetch(`https://${URL_PREFIX}layouts.api.axil.ai/layouts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(layoutBody),
      });
      const newLayout = await response.json();
      if (newLayout.statusCode && newLayout.statusCode !== 201) {
        throw new Error('Failed to create layout');
      }
      this.layouts = [...(this.layouts ?? []), newLayout];
      return newLayout;
    } catch (error) {
      return null;
    }
  }

  async createTemplateLayout(
    driverType: 'GED' | 'ELEC' | '*' | null,
    newChartLayout: ChartLayout,
    userEmail: string,
    token: string,
  ): Promise<Layout | null> {
    if (!userEmail) return null;
    const layoutBody = {
      id: uuidv4(),
      parentId: userEmail,
      unitId: '*',
      driver: driverType,
      charts: newChartLayout,
    };

    try {
      const response = await fetch(`https://${URL_PREFIX}layouts.api.axil.ai/layouts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(layoutBody),
      });
      const newLayout = await response.json();
      if (newLayout.statusCode && newLayout.statusCode !== 201) {
        throw new Error('Failed to create layout');
      }
      this.layouts = [...(this.layouts ?? []), newLayout];
      return newLayout;
    } catch (error) {
      return null;
    }
  }

  async updateDriverTemplateLayout(driver: 'GED' | 'ELEC', newChartLayout: ChartLayout, userEmail: string, token: string): Promise<Layout | null> {
    const existingDriverLayout = this.layouts?.find((layout) => layout.driver === driver && layout.unitId === '*');
    if (!existingDriverLayout) {
      return this.createTemplateLayout(driver, newChartLayout, userEmail, token);
    }
    return this.updateLayout(existingDriverLayout, newChartLayout, token);
  }

  async updateGlobalTemplateLayout(newChartLayout: ChartLayout, userEmail: string, token: string): Promise<Layout | null> {
    const existingGlobalLayout = this.layouts?.find((layout) => layout.unitId === '*' && layout.driver === '*');
    if (!existingGlobalLayout) {
      return this.createTemplateLayout('*', newChartLayout, userEmail, token);
    }
    return this.updateLayout(existingGlobalLayout, newChartLayout, token);
  }

  async deleteLayout(layoutId: string, token: string): Promise<Layout | null> {
    const layoutToDelete = this.layouts?.find((layout) => layout.id === layoutId);
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'if-match': layoutToDelete?.version?.toString() ?? '0',
      },
      method: 'DELETE',
    };
    try {
      const response = await fetch(`https://${URL_PREFIX}layouts.api.axil.ai/layouts/${layoutId}`, requestOptions);
      if (response.status !== 204) {
        throw new Error('Failed to delete layout');
      }
      const newLayouts = this.layouts?.filter((layout) => layout.id !== layoutId);
      this.layouts = newLayouts;
      return layoutToDelete as Layout;
    } catch (err) {
      return null;
    }
  }
}
