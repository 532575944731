import { useEffect, useState } from 'react';

import { assetFieldNames } from 'shared/constants/altitudeConstants';
import { getFilteredAssetList } from 'shared/helpers/assetHelpers';
import FluxVirtualizedTable from 'shared/components/flux-virtualized-table/FluxVirtualizedTable';

import { generateUnitViewRedirectAsset, openRedirect } from 'dashboard/helpers/dashboardHelpers';

import NotificationSettings from 'account-settings/components/notifications/NotificationSettings';
import { generateNotificationTableColumns } from 'account-settings/helpers/notificationTableHelpers';

import './NotificationsTabsContent.scss';

const NotificationsTabsContent = ({
  assets,
  dndSettings,
  preferences,
  activeSection,
  setDndSettings,
  filteredAssets,
  userSubscriptions,
  setFilteredAssets,
  updateDndPreferences,
  updateReceivingTexts,
  updateReceivingEmails,
  showConfirmationButtons,
  setModifiedSubscriptions,
  setShowConfirmationButtons,
}) => {
  const [assetFilterText, setAssetFilterText] = useState('');
  const [subscribedTableData, setSubscribedTableData] = useState(null);
  const [unsubscribedTableData, setUnsubscribedTableData] = useState(null);
  const [subscribedSortConfig, setSubscribedSortConfig] = useState({ key: null, direction: null });
  const [unsubscribedSortConfig, setUnsubscribedSortConfig] = useState({ key: null, direction: null });

  const generateLinkForTableRow = (item) => {
    const relatedEnterpriseObject = assets
      ?.find((entry) => entry?.asset_name === item?.asset_name);

    const link = generateUnitViewRedirectAsset(relatedEnterpriseObject);
    return link;
  };

  const selectUnitView = (event, item) => {
    const link = generateLinkForTableRow(item);
    openRedirect(event, link);
  };

  const clearDndSettings = () => {
    const newSettings = {
      ...preferences,
      dnd: false,
      dnd_start: null,
      dnd_stop: null,
    };

    setDndSettings(newSettings);
    updateDndPreferences();
  };

  const handleDndStopChange = (_, option) => {
    setDndSettings({
      ...dndSettings,
      dnd_stop: option.value,
    });

    setShowConfirmationButtons(true);
  };

  const handleDndStartChange = (_, option) => {
    setDndSettings({
      ...dndSettings,
      dnd_start: option.value,
    });

    setShowConfirmationButtons(true);
  };

  const addSubscriptionDetail = (record, label) => {
    const newSubscribedTableData = subscribedTableData?.map((item) => {
      if (item?.key?.toString() === record?.key) {
        const newItem = { ...item };
        if (label === 'all') {
          const newState = !newItem.all;
          newItem.all = newState;
          newItem.info = false;
          newItem.debug = false;
          newItem.danger = newState;
          newItem.success = newState;
          newItem.warning = newState;
        } else if (label in newItem) {
          newItem[label] = !newItem[label];
          if (newItem.all === true && newItem[label] === false) {
            newItem.all = false;
          }
        }
        return newItem;
      }
      return item;
    });

    const recordFound = newSubscribedTableData.some((item) => item.key.toString() === record.key);
    if (!recordFound) {
      const newItem = {
        key: record.key,
        all: false,
        info: false,
        debug: false,
        danger: false,
        success: false,
        warning: false,
      };
      if (label === 'all') {
        newItem.all = true;
        newItem.info = false;
        newItem.debug = false;
        newItem.danger = true;
        newItem.success = true;
        newItem.warning = true;
      } else {
        newItem[label] = true;
      }
      newSubscribedTableData.push(newItem);
    }

    const newUnsubscribedTableData = unsubscribedTableData?.map((item) => {
      if (item?.key?.toString() === record?.key) {
        const newItem = { ...item };
        if (label === 'all') {
          const newState = !newItem.all;
          newItem.all = newState;
          newItem.info = false;
          newItem.debug = false;
          newItem.danger = newState;
          newItem.success = newState;
          newItem.warning = newState;
        } else if (label in newItem) {
          newItem[label] = !newItem[label];
          if (newItem.all === true && newItem[label] === false) {
            newItem.all = false;
          }
        }
        return newItem;
      }
      return item;
    });

    const newFormattedSubscriptions = newSubscribedTableData?.map((entry) => ({
      [entry.key]: {
        info: false,
        debug: false,
        danger: entry.danger,
        success: entry.success,
        warning: entry.warning,
      },
    }));

    const updateSubscriptionsObject = newFormattedSubscriptions.reduce((acc, item) => ({ ...acc, ...item }), {});

    showConfirmationButtons();
    setSubscribedTableData(newSubscribedTableData);
    setUnsubscribedTableData(newUnsubscribedTableData);
    setModifiedSubscriptions(updateSubscriptionsObject);
  };

  const toggleSubscriptionDetail = (record, label) => {
    const newSubscribedTableData = subscribedTableData?.map((item) => {
      if (item?.key?.toString() === record?.key) {
        const newItem = { ...item };
        if (label === 'all') {
          const newState = !newItem.all;
          newItem.all = newState;
          newItem.info = false;
          newItem.debug = false;
          newItem.danger = newState;
          newItem.success = newState;
          newItem.warning = newState;
        } else if (label in newItem) {
          newItem[label] = !newItem[label];

          if (newItem.all === true && newItem[label] === false) {
            newItem.all = false;
          }
        }
        return newItem;
      }
      return item;
    });

    const newFormattedSubscriptions = newSubscribedTableData?.map((entry) => ({
      [entry.key]: {
        info: false,
        debug: false,
        danger: entry.danger,
        success: entry.success,
        warning: entry.warning,
      },
    }));

    const updateSubscriptionsObject = newFormattedSubscriptions
      .reduce((acc, item) => ({ ...acc, ...item }), {});

    showConfirmationButtons();
    setSubscribedTableData(newSubscribedTableData);
    setModifiedSubscriptions(updateSubscriptionsObject);
  };

  const sortByProperty = (arrayData, property, direction = 'ascending') => arrayData.sort((a, b) => {
    const propA = a[property]?.toString().toLowerCase();
    const propB = b[property]?.toString().toLowerCase();

    if (propA < propB) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (propA > propB) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const filterFields = [
    assetFieldNames.MODEL,
    assetFieldNames.ORG_NAME,
    assetFieldNames.SITE_NAME,
    assetFieldNames.ASSET_NAME,
  ];

  const notificationTableColumns = generateNotificationTableColumns(
    {
      activeSection,
      selectUnitView,
      addSubscriptionDetail,
      toggleSubscriptionDetail,
    },
  );

  useEffect(() => {
    setFilteredAssets(getFilteredAssetList(assets, null, assetFilterText, filterFields));
  }, [assetFilterText]);

  useEffect(() => {
    if (userSubscriptions) {
      const subscriptionKeys = Object.keys(userSubscriptions);
      const formattedSubscriptions = subscriptionKeys.map((key) => ({
        key,
        danger: userSubscriptions[key].danger,
        success: userSubscriptions[key].success,
        warning: userSubscriptions[key].warning,
        all: Object.values(userSubscriptions[key]).slice(1).every((value) => value),
      }));

      const activeSubscriptions = formattedSubscriptions
        .filter((item) => Object.values(item).slice(1).some((value) => value));

      const activeSubscriptionIds = activeSubscriptions?.map((sub) => sub.key);

      const subscribedUnits = filteredAssets
        ?.filter((asset) => activeSubscriptionIds.includes(asset.asset_id.toString()));

      const unsubscribedUnits = filteredAssets
        ?.filter((asset) => !activeSubscriptionIds.includes(asset.asset_id.toString()));

      const subscribedUnitRows = subscribedUnits.map((unitSubscription) => {
        const matchingFormattedSubscription = formattedSubscriptions
          .find((formattedSubscription) => formattedSubscription.key === unitSubscription.asset_id.toString());

        return {
          ...matchingFormattedSubscription,
          org_name: unitSubscription.org_name,
          site_name: unitSubscription.site_name,
          asset_name: unitSubscription.asset_name,
        };
      });

      const unsubscribedUnitRows = unsubscribedUnits.map((unit) => ({
        all: false,
        info: false,
        debug: false,
        danger: false,
        success: false,
        warning: false,
        key: unit.asset_id.toString(),
        org_name: unit.org_name,
        site_name: unit.site_name,
        asset_name: unit.asset_name,
      }));
      if (unsubscribedSortConfig?.key && unsubscribedSortConfig?.direction) {
        setUnsubscribedTableData(sortByProperty(unsubscribedUnitRows, unsubscribedSortConfig?.key, unsubscribedSortConfig?.direction));
      }
      if (subscribedSortConfig?.key && subscribedSortConfig?.direction) {
        setSubscribedTableData(sortByProperty(subscribedUnitRows, subscribedSortConfig?.key, subscribedSortConfig?.direction));
      }

      setSubscribedTableData(subscribedUnitRows);
      setUnsubscribedTableData(unsubscribedUnitRows);
    }
  }, [userSubscriptions]);

  return (
    <div className="notifications-tabs-content">
      <NotificationSettings
        dndSettings={dndSettings}
        preferences={preferences}
        activeSection={activeSection}
        clearDndSettings={clearDndSettings}
        setAssetFilterText={setAssetFilterText}
        handleDndStopChange={handleDndStopChange}
        handleDndStartChange={handleDndStartChange}
        updateReceivingTexts={updateReceivingTexts}
        updateReceivingEmails={updateReceivingEmails}
      />
      {subscribedTableData && activeSection === 'My Notifications' && (
        <FluxVirtualizedTable
          data={subscribedTableData}
          filterString={assetFilterText}
          sortConfig={subscribedSortConfig}
          columns={notificationTableColumns}
          setSortConfig={setSubscribedSortConfig}
        />
      )}
      {unsubscribedTableData && activeSection === 'Add New' && (
        <FluxVirtualizedTable
          data={unsubscribedTableData}
          filterString={assetFilterText}
          columns={notificationTableColumns}
          sortConfig={unsubscribedSortConfig}
          setSortConfig={setUnsubscribedSortConfig}
        />
      )}
    </div>
  );
};

export default NotificationsTabsContent;
