import { useSelector } from 'react-redux';
import { IoAddOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Button, DateRangePicker } from '@flogistix/flo-ui';

import {
  standardizeDate,
  parameterizeDate,
  generateVaporCompositionTableData,
} from 'shared/helpers/emissionsHelpers';
import {
  IEmissionData,
  IGasAnalysisData,
  donutChartColors,
  ICalculationFactorsData,
} from 'shared/constants/emissionsCalculatorConstants';
import { selectSession } from 'shared/reducers/sessionReducer';
import EmissionsCalculatorTab from 'shared/components/EmissionsCalculatorTab';
import { getGasAnalysisForUnit } from 'shared/repositories/gasAnalysisRepository';
import { getCalculationFactors, getUnitEmissionDetails } from 'shared/repositories/emissionsCalculatorRepository';

import { vaporCompositionTableColumns } from 'single-asset/constants/emissionsConstants';
import EmissionStatCard from 'single-asset/components/emissions-calculator/EmissionStatCard';
import CurrentEmissionsSection from 'single-asset/components/emissions-calculator/CurrentEmissionsSection';
import EquationTable from 'single-asset/components/emissions-calculator/equations-breakdown-tab/EquationTable';
import DonutChart from 'single-asset/components/emissions-calculator/vapor-composition-breakdown-tab/DonutChart';
import TotalEmissionsBreakdownCard from 'single-asset/components/emissions-calculator/emissions-results-tab/TotalEmissionsBreakdownCard';
import VaporCompositionBreakdownTable, { VaporCompositionBreakdownTableRow } from 'single-asset/components/emissions-calculator/vapor-composition-breakdown-tab/VaporCompositionBreakdownTable';

import './EmissionsCalculatorPage.scss';

const today = new Date();
const pastDate = new Date();
pastDate.setDate(today.getDate() - 28);

interface EmissionsCalculatorPageProps { assetId: number, assetName: string }

const EmissionsCalculatorPage: React.FC<EmissionsCalculatorPageProps> = ({ assetId, assetName }) => {
  const navigate = useNavigate();
  const currentSession = useSelector(selectSession);

  const [showDonutChart, setShowDonutChart] = useState(true);
  const [currentTab, setCurrentTab] = useState('Emissions Results');
  const [emissionsTimeFrame, setEmissionsTimeFrame] = useState('');
  const [emissionsDataPending, setEmissionsDataPending] = useState(false);
  const [emissionData, setEmissionData] = useState<IEmissionData | null>(null);

  const [endDate, setEndDate] = useState<Date>(today);
  const [startDate, setStartDate] = useState<Date>(pastDate);

  const [gasAnalysis, setGasAnalysis] = useState<IGasAnalysisData | null>(null);
  const [calculationFactors, setCalculationFactors] = useState<ICalculationFactorsData | null>(null);

  const breakdownTableData = generateVaporCompositionTableData(emissionData, gasAnalysis);
  const breakdownDonutData = breakdownTableData.filter((entry) => entry?.label !== 'TOTAL');

  const getEmissionsData = async () => {
    setEmissionsDataPending(true);

    const formattedEndDate = parameterizeDate(endDate);
    const formattedStartDate = parameterizeDate(startDate);

    try {
      const response = await getUnitEmissionDetails({
        unitId: assetId,
        endDate: formattedEndDate,
        startDate: formattedStartDate,
        accessToken: currentSession?.token,
      });

      if (!response?.ok) {
        throw new Error(`Fetch failed with status: ${response?.status}`);
      }

      const responseData = await response.json();
      setEmissionData(responseData);
    } catch (error) {
      console.error('Error fetching emissions data:', error);
    } finally {
      setEmissionsDataPending(false);
    }
  };

  const getEmissionsCalculationFactors = async () => {
    try {
      const response = await getCalculationFactors({
        unitId: assetId,
        accessToken: currentSession?.token,
      });

      if (!response?.ok) {
        throw new Error(`Fetch failed with status: ${response?.status}`);
      }

      const responseData = await response?.json();

      setCalculationFactors(responseData);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  const getGasAnalysis = async () => {
    try {
      const response = await getGasAnalysisForUnit({
        accessToken: currentSession?.token,
        gasAnalysisId: calculationFactors?.gasAnalysisId,
      });

      if (!response?.ok) {
        throw new Error(`Fetch failed with status: ${response?.status}`);
      }

      const responseData = await response?.json();

      setGasAnalysis(responseData);
    } catch (error) {
      console.error('Error fetching gas analysis:', error);
    }
  };

  const clearEmissionDates = () => {
    setEndDate(today);
    setStartDate(pastDate);
  };

  const handleEmissionsDates = () => {
    const formattedEndDate = standardizeDate(endDate);
    const formattedStartDate = standardizeDate(startDate);
    const formattedDisplayText = `${formattedStartDate} through ${formattedEndDate}`;

    setEmissionsTimeFrame(formattedDisplayText);
  };

  const handleGasAnalysisRedirect = () => {
    navigate(`/emissions/analysis?unit_id=${assetName}`);
  };

  const handleEndDate = (newEndDate: Date | null) => {
    setEndDate(newEndDate as Date);
  };

  const handleStartDate = (newStartDate: Date | null) => {
    setStartDate(newStartDate as Date);
  };

  useEffect(() => {
    handleEmissionsDates();
  }, [startDate, endDate]);

  useEffect(() => {
    calculationFactors && getGasAnalysis();
  }, [calculationFactors]);

  useEffect(() => {
    if (currentSession?.token && assetId) {
      getEmissionsData();
      getEmissionsCalculationFactors();
    }
  }, [currentSession, assetId, emissionsTimeFrame]);

  return (
    <section className="emissions-calculator-page-container">
      {(emissionsDataPending)
        ? <h1>Loading emissions data...</h1>
        : (
          <section className="emissions-calculator-page">
            <div className="emissions-calculator-page-header">
              <CurrentEmissionsSection
                emissionsData={emissionData}
                timeFrame={emissionsTimeFrame}
              />
              <div className="emissions-calculator-page-header-buttons">
                <Button onClick={handleGasAnalysisRedirect}>
                  <IoAddOutline
                    size={14}
                    style={{ marginRight: '8px' }}
                  />
                  <span>Gas Analysis</span>
                </Button>
              </div>
            </div>
            <div className="emissions-calculator-tabs">
              <EmissionsCalculatorTab
                label="Emissions Results"
                active={currentTab === 'Emissions Results'}
                handleClick={() => setCurrentTab('Emissions Results')}
                style={{
                  borderTopLeftRadius: '10px',
                  borderBottomLeftRadius: '10px',
                }}
              />
              <EmissionsCalculatorTab
                style={{ borderRadius: '0' }}
                label="Vapor Composition Breakdown"
                active={currentTab === 'Vapor Composition Breakdown'}
                handleClick={() => setCurrentTab('Vapor Composition Breakdown')}
              />
              <EmissionsCalculatorTab
                label="Equation Breakdown"
                active={currentTab === 'Equation Breakdown'}
                handleClick={() => setCurrentTab('Equation Breakdown')}
                style={{
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px',
                  borderRight: 'solid 1px #D0D5DD',
                }}
              />
              <div className="datepicker-container">
                <DateRangePicker
                  borderColor="#D0D5DD"
                  selectedEndDate={endDate}
                  onClear={clearEmissionDates}
                  selectedStartDate={startDate}
                  setSelectedEndDate={handleEndDate}
                  setSelectedStartDate={handleStartDate}
                  onSelectionChange={handleEmissionsDates}
                />
              </div>
            </div>
            {currentTab === 'Emissions Results' && (
              <section className="emissions-results-view">
                <TotalEmissionsBreakdownCard
                  gasAnalysis={gasAnalysis}
                  emissionsData={emissionData}
                  calculationFactors={calculationFactors}
                />
                <div className="emissions-results-cards">
                  <section className="emissions-results-cards-row">
                    <EmissionStatCard
                      style={{ width: '100%' }}
                      label="Volume of Vapors Captured (MCF)"
                      tooltipTitle="Volume of Vapors Captured (MCF)"
                      value={emissionData?.volume?.toFixed(2)?.toString()}
                      tooltipDescription="The total volume of all gas captured by vapor recovery."
                    />
                  </section>
                  <section className="emissions-results-cards-row">
                    <EmissionStatCard
                      label="NGLs Captured"
                      tooltipTitle="NGLs Captured"
                      style={{ marginTop: '16px' }}
                      value={emissionData?.flared?.ngl?.total?.toFixed(2)?.toString()}
                      tooltipDescription="The total volume of all gas captured by vapor recovery."
                    />
                    <EmissionStatCard
                      label="MM BTUs Saved"
                      tooltipTitle="MM BTUs Saved"
                      style={{ marginTop: '16px' }}
                      tooltipDescription="The efficiency of all captured flare emissions."
                      value={emissionData?.flared?.btu?.total?.toFixed(2)?.toString()}
                    />
                  </section>
                </div>
              </section>
            )}
            {currentTab === 'Vapor Composition Breakdown' && (
              <section
                className="vapor-composition-view"
                style={{ width: showDonutChart ? '716px' : '1120px' }}
              >
                <div className="vapor-composition-card">
                  <div className="vapor-composition-card-content">
                    {showDonutChart ? (
                      <DonutChart
                        size={200}
                        ringWidth={40}
                        colors={donutChartColors}
                        data={breakdownDonutData}
                      />
                    ) : (
                      <VaporCompositionBreakdownTable
                        columns={vaporCompositionTableColumns}
                        dataRows={breakdownTableData as VaporCompositionBreakdownTableRow[]}
                      />
                    )}
                  </div>
                </div>
                <span className="vapor-composition-card-border" />
                <Button
                  variation="black-outline"
                  style={{ margin: '24px', alignSelf: 'flex-end' }}
                  onClick={() => setShowDonutChart(!showDonutChart)}
                >
                  {showDonutChart ? 'View Mol %' : 'View Chart'}
                </Button>
              </section>
            )}
            {currentTab === 'Equation Breakdown' && (
              <section className="equation-breakdown-view">
                <EquationTable />
              </section>
            )}
          </section>
        )}
    </section>
  );
};

export default EmissionsCalculatorPage;
