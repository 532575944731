import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';

const ascendingSorter = (item1, item2, propertyName) => `${item1?.[propertyName]}`.localeCompare(`${item2?.[propertyName]}`, 'en', { ignorePunctuation: true, numeric: true });
const descendingSorter = (item1, item2, propertyName) => -1 * ascendingSorter(item1, item2, propertyName);

export const createTableSorter = (propertyName, ascending = true) => (item1, item2) => (ascending
  ? ascendingSorter(item1, item2, propertyName)
  : descendingSorter(item1, item2, propertyName));

export function tupleizeArray(listToTupelize) {
  const copyOfListToTupelize = [...listToTupelize];
  let tuplelList = [];

  while (copyOfListToTupelize.length > 0) {
    tuplelList = [...tuplelList, copyOfListToTupelize.splice(0, 2)];
  }

  return tuplelList;
}

export const setMissingElementValuesToDefault = (element, defaultValue = MISSING_FIELD_VALUE_PLACEHOLDER) => Object.entries(element).reduce((acc, [key, value]) => ({
  ...acc,
  [key]: value ?? defaultValue,
}), {});

export const getUnitStatColor = (label, stat) => {
  if (!stat) {
    return 'black';
  }

  if (label === 'RTP') {
    if (stat?.avg_rtp >= 98 || stat?.value >= 98) {
      return '#00c278';
    }

    return '#FF5353';
  }

  if (label === 'Status') {
    if (stat?.value === 'Running') {
      return '#00c278';
    }

    if (stat?.value === 'Off') {
      return '#FF5353';
    }

    if (stat?.value === 'Faulted') {
      return '#FF5353';
    }
  }

  return 'black';
};

export const getDashboardRuntimeStatColor = (value) => {
  if (Number(value) >= 98) {
    return 'runtime-green';
  }
  return 'runtime-red';
};

export const openRedirect = (event, url) => {
  if (event.ctrlKey || event.metaKey) {
    window.open(url, '_blank');
  } else {
    window.location.href = url;
  }
};

export const generateUnitViewRedirect = (enterpriseObject) => `/asset/${enterpriseObject?.organization?.orgId}/${enterpriseObject?.site?.siteId}/${enterpriseObject?.name}/${enterpriseObject?.deviceId}`;
export const generateUnitViewRedirectAsset = (asset) => `/asset/${asset?.org_id}/${asset?.site_id}/${asset?.asset_name}/${asset?.device_id}`;

export const getOrgValue = (str) => {
  const orgParam = str.match(/[?&]org=([^&]*)/);
  return orgParam ? orgParam[1] : null;
};

export const getSiteValue = (str) => {
  const siteParam = str.match(/[?&]site=([^&]*)/);
  return siteParam ? siteParam[1] : null;
};

export const getAreaValue = (str) => {
  const areaParam = str.match(/[?&]area=([^&]*)/);
  return areaParam ? areaParam[1] : null;
};

export const getStartDate = (str) => {
  const startDateParam = str.match(/[?&]start_date=([^&]*)/);
  return startDateParam ? startDateParam[1] : null;
};

export const getEndDate = (str) => {
  const endDateParam = str.match(/[?&]end_date=([^&]*)/);
  return endDateParam ? endDateParam[1] : null;
};

export const getOrgIdValue = (str) => {
  const param = str.match(/[?&]org_id=([^&]*)/);
  return param ? param[1] : null;
};

export const getUnitIdValue = (str) => {
  const param = str.match(/[?&]unit_id=([^&]*)/);
  return param ? param[1] : null;
};

export const getAreaIdValue = (str) => {
  const param = str.match(/[?&]group_id=([^&]*)/);
  return param ? param[1] : null;
};

export const getSiteIdValue = (str) => {
  const param = str.match(/[?&]site_id=([^&]*)/);
  return param ? param[1] : null;
};

export const getOrgIdValues = (str) => {
  const params = [...str.matchAll(/[?&]org_id=([^&]*)/g)];
  return params.length ? params.map((match) => match[1]) : null;
};

export const getSiteIdValues = (str) => {
  const params = [...str.matchAll(/[?&]site_id=([^&]*)/g)];
  return params.length ? params.map((match) => match[1]) : null;
};

export const getAreaIdValues = (str) => {
  const params = [...str.matchAll(/[?&]group_id=([^&]*)/g)];
  return params.length ? params.map((match) => match[1]) : null;
};

export const removeDateParameters = (url) => {
  let newUrl = url
    .replace(/([?&]start_date=[^&]*)/g, '')
    .replace(/([?&]end_date=[^&]*)/g, '');

  newUrl = newUrl.replace(/&&/g, '&');
  newUrl = newUrl.replace(/\?&/, '?');

  if (newUrl.endsWith('?') || newUrl.endsWith('&')) {
    newUrl = newUrl.slice(0, -1);
  }

  newUrl = newUrl.replace(/\?$/, '');

  return newUrl;
};

export const removeOrgIdParameter = (url) => {
  let newUrl = url.replace(/([?&])org_id=[^&]*(&?)/g, (match, p1, p2) => {
    if (p1 === '?') return p2 ? '?' : '';
    return p2 ? '&' : '';
  });

  newUrl = newUrl.replace(/&&/g, '&');

  newUrl = newUrl.replace(/\?&/, '?');

  if (newUrl.endsWith('?') || newUrl.endsWith('&')) {
    newUrl = newUrl.slice(0, -1);
  }

  return newUrl;
};

export const removeSiteIdParameter = (url) => {
  let newUrl = url.replace(/([?&])site_id=[^&]*(&?)/g, (match, p1, p2) => {
    if (p1 === '?') return p2 ? '?' : '';
    return p2 ? '&' : '';
  });

  newUrl = newUrl.replace(/&&/g, '&');

  newUrl = newUrl.replace(/\?&/, '?');

  if (newUrl.endsWith('?') || newUrl.endsWith('&')) {
    newUrl = newUrl.slice(0, -1);
  }

  return newUrl;
};

export const removeAreaIdParameter = (url) => {
  let newUrl = url.replace(/([?&])group_id=[^&]*(&?)/g, (match, p1, p2) => {
    if (p1 === '?') return p2 ? '?' : '';
    return p2 ? '&' : '';
  });

  newUrl = newUrl.replace(/&&/g, '&');

  newUrl = newUrl.replace(/\?&/, '?');

  if (newUrl.endsWith('?') || newUrl.endsWith('&')) {
    newUrl = newUrl.slice(0, -1);
  }

  return newUrl;
};

export const removeOrgParameter = (url) => {
  let newUrl = url.replace(/([?&])org=[^&]*(&?)/g, (match, p1, p2) => {
    if (p1 === '?') return p2 ? '?' : '';
    return p2 ? '&' : '';
  });

  newUrl = newUrl.replace(/&&/g, '&');

  newUrl = newUrl.replace(/\?&/, '?');

  if (newUrl.endsWith('?') || newUrl.endsWith('&')) {
    newUrl = newUrl.slice(0, -1);
  }

  return newUrl;
};

export const removeSiteParameter = (url) => {
  let newUrl = url.replace(/([?&])site=[^&]*(&?)/g, (match, p1, p2) => {
    if (p1 === '?') return p2 ? '?' : '';
    return p2 ? '&' : '';
  });

  newUrl = newUrl.replace(/&&/g, '&');

  newUrl = newUrl.replace(/\?&/, '?');

  if (newUrl.endsWith('?') || newUrl.endsWith('&')) {
    newUrl = newUrl.slice(0, -1);
  }

  return newUrl;
};

export const removeAreaParameter = (url) => {
  let newUrl = url.replace(/([?&])area=[^&]*(&?)/g, (match, p1, p2) => {
    if (p1 === '?') return p2 ? '?' : '';
    return p2 ? '&' : '';
  });

  newUrl = newUrl.replace(/&&/g, '&');

  newUrl = newUrl.replace(/\?&/, '?');

  if (newUrl.endsWith('?') || newUrl.endsWith('&')) {
    newUrl = newUrl.slice(0, -1);
  }

  return newUrl;
};

export const removeUnitParameter = (url) => {
  let newUrl = url.replace(/([?&])unit_id=[^&]*(&?)/g, (match, p1, p2) => {
    if (p1 === '?') return p2 ? '?' : '';
    return p2 ? '&' : '';
  });

  newUrl = newUrl.replace(/&&/g, '&');

  newUrl = newUrl.replace(/\?&/, '?');

  if (newUrl.endsWith('?') || newUrl.endsWith('&')) {
    newUrl = newUrl.slice(0, -1);
  }

  return newUrl;
};
