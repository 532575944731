/* eslint-disable react/jsx-no-useless-fragment */
import { useAuth0 } from '@auth0/auth0-react';
import { BreakpointProvider } from 'react-socks';
import { ThemeProvider } from 'styled-components';
import { InfoBanner } from '@flogistix/flo-ui';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigProvider, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import ProfileModal from 'shared/components/ProfileModal';
import AppNavigation from 'shared/components/AppNavigation';
import { selectSession } from 'shared/reducers/sessionReducer';
import { isUserExternal } from 'shared/helpers/permissionHelpers';
import { fetchUserGroups } from 'shared/actions/permissionsActions';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { getAppClassNamesByTheme } from 'shared/helpers/themeHelpers';
import { REPORTING_GROUP } from 'shared/constants/permissionsConstants';
import { clearSession, setSession } from 'shared/actions/sessionActions';
import { selectNotifications } from 'shared/reducers/notificationsSlice';
import { removeNotification } from 'shared/actions/notificationsActions';
import { selectOrganizations } from 'shared/reducers/organizationsSlice';
import { fetchUserOrganizations } from 'shared/actions/organizationsActions';
import { ChartingContext } from 'context/ChartingContext';
import FleetDecoder from 'fleet-decoder/pages/FleetDecoder';

import DashboardPageContainer from 'dashboard/pages/DashboardPageContainer';

import AssetPageContainer from 'single-asset/pages/AssetPageContainer';

import ReportingPage from 'reporting/pages/ReportingPage';

import AccountSettingsPage from 'account-settings/pages/AccountSettingsPage';
import { fetchUserSubscriptions } from 'shared/actions/subscriptionsActions';

import EmissionsPage from 'emissions/pages/EmissionsPage';

import './App.scss';
import './index.scss';

const { localStorage } = window;

const FLOGISTIX_BRAND_COLOR = '#F05921';

const SEVEN_DAYS = 604800000;

const App = () => {
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const dispatch = useDispatch();

  const session = useSelector(selectSession);
  const { organizations } = useSelector(selectOrganizations);
  const theme = useSelector((state) => state.resources.theme);
  const demoMode = useSelector((state) => state.resources.demo);
  const { shownNotification } = useSelector(selectNotifications);
  const themeStyles = useSelector((state) => state.resources.themeStyles);
  const { groups, fetchAttempts: groupFetchAttempts } = useSelector(selectPermissions);
  const { body: localGroups } = JSON.parse(localStorage.getItem('groups') ?? '{}');
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [isPendoInitialized, setIsPendoInitialized] = useState(false);

  const openProfileModal = () => setProfileModalOpen(true);
  const closeProfileModal = () => setProfileModalOpen(false);

  const [api, contextHolder] = notification.useNotification();
  const appClassNames = getAppClassNamesByTheme({ themeStyles, demo: demoMode.demo });

  const { charting } = useContext(ChartingContext);

  const defaultRoutes = [
    {
      link: '/emissions',
      path: '/emissions/*',
      exact: true,
      title: 'Emissions',
      mainNav: true,
      navExact: false,
      component: React.useCallback(() => <EmissionsPage />, []),
    },
    {
      link: '/decoder',
      path: '/decoder',
      exact: true,
      title: 'Decoder',
      mainNav: true,
      component: FleetDecoder,
    },
    {
      link: '/dashboard',
      path: '/dashboard/*',
      exact: true,
      title: 'Dashboard',
      mainNav: true,
      navExact: false,
      component: React.useCallback(() => user && (
        <DashboardPageContainer user={user} />
      ), [user]),
    },
    {
      title: 'Asset',
      path: '/asset/:org_id?/:site_id?/:asset_id?/:device_id?/*',
      link: '/asset/:org_id?/:site_id?/:asset_id?/:device_id?',
      mainNav: true,
      component: React.useCallback(() => user && (
        <AssetPageContainer user={user} />
      ), [user]),
    },
    {
      title: 'Account Settings',
      path: '/notifications',
      link: 'notifications',
      component: React.useCallback(() => session?.user && <AccountSettingsPage />, [session]),
    },
    {
      title: 'Reporting',
      path: '/reports/*',
      link: 'reports',
      icon: 'chart',
      component: ReportingPage,
    },
  ];

  const clearInfoRedirectAndLogin = () => {
    dispatch(clearSession());
    loginWithRedirect();
  };

  window.ononline = () => {
    setIsOffline(false);
  };

  window.onoffline = () => {
    setIsOffline(true);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!navigator.onLine) {
      const { timestamp: userTimestamp, body: userInfo } = JSON.parse(localStorage.getItem('user') ?? '{}');
      const { timestamp: orgTimestamp } = JSON.parse(localStorage.getItem('organizations') ?? '{}');
      const { timestamp: groupTimestamp } = JSON.parse(localStorage.getItem('groups') ?? '{}');
      if (!userTimestamp || !orgTimestamp || !groupTimestamp) return clearInfoRedirectAndLogin();
      const userAge = new Date().getTime() - userTimestamp;
      const orgAge = new Date().getTime() - orgTimestamp;
      const groupAge = new Date().getTime() - groupTimestamp;

      if (userAge > SEVEN_DAYS || orgAge > SEVEN_DAYS || groupAge > SEVEN_DAYS) {
        localStorage.removeItem('user');
        localStorage.removeItem('organizations');
        localStorage.removeItem('groups');
        return clearInfoRedirectAndLogin();
      }
      dispatch(setSession({ user: userInfo, isAuthenticated: true, offline: true }));
    } else if (!isLoading && !isAuthenticated) {
      clearInfoRedirectAndLogin();
    } else if (isAuthenticated) {
      return (async () => {
        const token = await getAccessTokenSilently();
        dispatch(setSession({
          user,
          isAuthenticated,
          token,
        }));
      })();
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(themeStyles));
  }, [themeStyles]);

  useEffect(() => {
    localStorage.setItem('demo', JSON.stringify(demoMode));
  }, [demoMode.demo]);

  useEffect(() => {
    session?.token && dispatch(fetchUserOrganizations());
    session?.token && dispatch(fetchUserSubscriptions());
    session?.token && !groupFetchAttempts && !groups.length && dispatch(fetchUserGroups());
  }, [session]);

  useEffect(() => {
    if (!isPendoInitialized && !organizations?.length && user) {
      const external_user = isUserExternal(user?.email);

      const multiOrganizationName = organizations?.length > 1
        ? 'Multiple Organizations'
        : organizations?.at(0)?.orgName;

      const orgName = external_user
        ? multiOrganizationName
        : 'Flogistix';

      window.pendo.initialize({
        visitor: {
          id: user?.email,
          full_name: user?.name,
        },
        account: {
          id: orgName,
        },
      });

      setIsPendoInitialized(true);
    }
  }, [organizations, user]);

  useEffect(() => {
    if (shownNotification) {
      const { type, message, duration = '3' } = shownNotification;
      const notificationProps = {
        icon: <></>,
        closeIcon: null,
        onClose: () => dispatch(removeNotification()),
        placement: 'bottom',
        duration,
        message,
      };
      if (type === 'success') api.success(notificationProps);
      else if (type === 'error') api.error(notificationProps);
      else api.info(notificationProps);
    }
  }, [shownNotification]);

  useEffect(() => {
    if (user?.email && session?.token && charting.layoutFetchAttempts === 0) {
      charting.fetchLayouts(user.email, session.token);
    }
  }, [user, session]);

  useEffect(() => {
    if (organizations.length) {
      const localOrgs = { body: organizations, timestamp: new Date().getTime() };
      localStorage.setItem('organizations', JSON.stringify(localOrgs));
    }
    if (groups.length) {
      const localUserGroups = { body: groups, timestamp: new Date().getTime() };
      localStorage.setItem('groups', JSON.stringify(localUserGroups));
    }
    if (user) {
      const localUser = { body: user, timestamp: new Date().getTime() };
      localStorage.setItem('user', JSON.stringify(localUser));
    }
  }, [groups, user, organizations]);

  return (
    <div className={appClassNames}>
      <BreakpointProvider>
        <ThemeProvider theme={theme}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Inter',
                colorPrimary: FLOGISTIX_BRAND_COLOR,
              },
            }}
          >
            <AppNavigation
              user={user}
              openProfileModal={openProfileModal}
              canAccessReports={(isOffline ? localGroups : groups).includes(REPORTING_GROUP)}
              isOffline={isOffline}
            />
            <div>
              <Routes>
                {defaultRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    element={(
                      <route.component
                        user={user}
                        isAuthenticated={isAuthenticated}
                      />
                    )}
                  />
                ))}
                {isAuthenticated
                  && (
                    <Route
                      path="/"
                      element={(
                        <Navigate
                          replace
                          to="dashboard"
                        />
                      )}
                    />
                  )}
              </Routes>
            </div>
            <ProfileModal
              user={user}
              isOpen={profileModalOpen}
              closeModal={closeProfileModal}
            />
            { isOffline && (
              <InfoBanner
                className="offline-banner"
                title="You are currently offline."
                subtitle="Data access will be limited until a connection is established."
              />
            )}
            {contextHolder}
          </ConfigProvider>
        </ThemeProvider>
      </BreakpointProvider>
    </div>
  );
};

export default App;
